/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SelectCategory({ selectedCategory, setSelectedCategory }) {
  // localization
  const { t } = useTranslation();
  const { title, categories } = t('selectCategory', { returnObjects: true });

  /* ******************************** HOOKS ******************************* */
  const initialCategory = useMemo(
    () => categories?.length > 0 && categories[0]?.name,
    [categories],
  );

  useEffect(() => {
    setSelectedCategory(initialCategory);
  }, [setSelectedCategory, initialCategory]);

  /* ******************************** RENDER HELPERS ******************************* */
  function Buttons() {
    if (!categories?.length > 0) return null;
    return categories?.map(({ id, name, buttonValue }) => (
      <button
        key={id}
        type="button"
        className={`select-category__btn ${
          selectedCategory === name ? 'active' : ''
        }`}
        onClick={() => setSelectedCategory(name)}
      >
        {buttonValue}
      </button>
    ));
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="select-category">
      <h2 className="select-category__title">{title}</h2>
      <div className="sc-buttons-category">
        <Buttons />
      </div>
    </Container>
  );
}

SelectCategory.propTypes = {
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
};

SelectCategory.defaultProps = {
  selectedCategory: undefined,
  setSelectedCategory: () => {},
};

export default SelectCategory;
