/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';

// UI lib comoponents

// Local UI components
import SelectCategory from '../SelectCategory';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import schema from '../../../shared/FormSchemas/partnersFormSchema.json';

// Style
import './index.scss';
import QuotationRequest from '../../QuotationRequest';
import { useCountries, useDomains } from '../../../shared/Hooks/useData';
import { API_PARTNER_FORM } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Form() {
  /* ******************************** HOOKS ******************************* */
  const { language } = useI18next();
  const [selectedCategory, setSelectedCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const { countries } = useCountries();
  const { domains } = useDomains(selectedCategory);

  const selectData = useMemo(() => {
    return {
      country: countries.map(({ attributes, id }) => ({
        label: attributes[`name_${language}`],
        value: id,
      })),
      domain: domains.map(({ attributes }) => ({
        label: attributes?.name,
        value: attributes?.name,
      })),
    };
  }, [countries, domains, language]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post('https://formspree.io/f/mgebkvpe', {
        ...data,
        category: selectedCategory,
      });

      await axios.post(API_PARTNER_FORM, {
        data: {
          ...data,
          category: selectedCategory,
          country: countries.find(
            ({ attributes }) => attributes?.name === data?.country,
          )?.id,
          domain: countries.find(
            ({ attributes }) => attributes?.name === data?.country,
          )?.id,
        },
      });
      setResponse('SUCCESS');
    } catch (error) {
      setResponse('ERROR');
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setResponse();
    }, 3000);
  }, [response]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="partners-form">
      <SelectCategory
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <QuotationRequest
        schema={schema}
        onSubmit={onSubmit}
        loading={loading}
        response={response}
        selectData={selectData}
      />
    </div>
  );
}

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
