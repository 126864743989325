/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */
  // localization
  const { t } = useTranslation();
  const { title, description1, description2 } = t('hero', {
    returnObjects: true,
  });
  const list = description2.map(({ id, item, classT, linebreak }) => (
    <>
      <p key={id} className={`hero-contain ${classT}`}>
        {item}
        {linebreak ? (
          <>
            <br />
            <br />
          </>
        ) : null}
      </p>
    </>
  ));
  return (
    <div>
      <div className="hero" fluid>
        <div className="hero-titlepartner">{title}</div>
        <div className="hero-description">
          <div className="hero-description-1">{description1}</div>
          <div className="hero-description-2">{list}</div>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
